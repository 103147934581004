// import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


// import VueHtml2Canvas from 'vue-html2canvas';
 
// Vue.use(VueHtml2Canvas);

// const app = createApp(App)
// app.use(BootstrapVue)

// app.mount('#app')
new Vue({
    el: "#app",
    router,
    render: h=> h(App)
})